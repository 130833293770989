import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Okara",
  "preis": "6,00 - 8,00 € pro Kilo"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Okara, die andere Seite der Sojabohne. Unser veganes Gehacktes, Basis für neue kulinarische Experimente und Abenteuer. Stark im Bereich Eiweiß, Ballasstoff, Spurenelemente, wie Eisen.
Und mega lecker. Okara funktioniert in Kuchen als Eiweißspender, als Basis von unseren Okarabällchen, als Hackersatz beim Burgerbau und überall da, wo sich Gastronaut*innen wohl fühlen.
Der Phantasie sind keine Grenzen gesetzt und Okara lässt sich mit ziemlich vielen Zutaten abbinden. Es funktioniert auch gut als Basis für glutenfreies Essen. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      